<script>
export default {
  name: 'Mensagem',
  methods: {},
};
</script>

<template>
  <div class="msg-alert">
    <div class="title-msg-alert">
      <p>
        <slot name="title"></slot>
      </p>
    </div>
    <div class="msg">
      <p>
        <slot name="msg"></slot>
      </p>
    </div>
  </div>
</template>

<style>
.msg-alert {
  position: absolute;
  width: 541px;
  height: 81px;
  left: 492px;
  top: 43px;

  margin: 0 auto;

  background: #FFFFFF;
  border: 1px solid #DDDFE0;
  box-sizing: border-box;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.title-msg-alert {
  position: absolute;
  width: 100%;
  height: 34px;

  background: #FCDDE2;
  border: 1px solid #FBCFD6;
  box-sizing: border-box;
}

.title-msg-alert p {
  margin: 8px 10px 10px 8px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #7D2C38;
}

.msg {
  position: absolute;
  margin-top: 50px;
  text-align: center;
  width: 100%;

  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #6C757D;
}
</style>