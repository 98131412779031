<template>
  <div>
    <div class="tabs2">
      <div v-bind:class="{ 'active': paginaInicial }" class="tab">
        <p v-on:click="open('PaginaInicial')" class="title">Página inicial</p>
        <p class="bar"></p>
      </div>
      <div v-bind:class="{ 'active': campanhas }" class="tab">
        <p v-on:click="open('Campanhas')" class="title">Campanhas</p>
        <p class="bar"></p>
      </div>
      <div v-bind:class="{ 'active': configuracoes }" class="tab">
        <p v-on:click="open('Configuracoes')" class="title">Configurações</p>
        <p class="bar"></p>
      </div>
      <div class="tab">
        <p class="title">&nbsp;</p>
        <p class="bar"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabBar',
  props: ['index'],
  computed: {
      paginaInicial: function () {
        return this.index === 'pagina-inicial'
      },
      campanhas: function () {
        return this.index === 'campanhas'
      },
      configuracoes: function () {
        return this.index === 'configuracoes'
      }
  },
  methods: {
    open: function (name) {
      if (this.$route.name !== name) {
        this.$router.push({name: name})
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.tabs2 {
  display: flex;
  /* margin-bottom: 1em; */
  text-align: center;
}

div.tab {
  width: 13rem;
}

div.tab:last-child {
  width: 100%;
}

div.tab p.title {
  color: #6C757D;
  font-family: 'Nunito';
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 0;
}

div.tab.active p.title {
  color: #008FFB;
}

div.tab p.bar {
  background-color: #CED2D6;
  height: 2px;
  margin-top: 0.5rem;
}

div.tab.active p.bar {
  background-color: #008FFB;
}
</style>
