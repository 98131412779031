<template>
  <div>
    <h1>Relatório de campanhas</h1>
    <div class="criar-campanha">
      <button v-on:click="openCampaign()" class="btn btn-primary">
        <template v-if="linkLoaded">Criar nova campanha</template>
        <i v-else class="fas fa-spinner fa-pulse"></i>
      </button>
    </div>
    <div class="summary">
      <div class="ativas card-campanha">
        <div class="icon-campanha"><img src="../assets/img/icon-campanha-ativa.svg"></div>
        <div>
          <span class="number">{{ ativas }}</span>
          <span class="label">Ativas</span>
        </div>
      </div>
      <div class="pausadas card-campanha">
        <div class="icon-campanha"><img src="../assets/img/icon-campanha-pausada.svg"></div>
        <div>
          <span class="number">{{ pausadas }}</span>
          <span class="label">Pausadas</span>
        </div>
      </div>
      <div class="rejeitadas card-campanha">
        <div class="icon-campanha"><img src="../assets/img/icon-campanha-rejeitada.svg"></div>
        <div>
          <span class="number">{{ rejeitadas }}</span>
          <span class="label">Rejeitadas</span>
        </div>
      </div>
      <div class="finalizadas card-campanha">
        <div class="icon-campanha"><img src="../assets/img/icon-campanha-finalizada.svg"></div>
        <div>
          <span class="number">{{ finalizadas }}</span>
          <span class="label">Finalizadas</span>
        </div>
      </div>
    </div>
    <alert-error v-if="error">
      <template v-slot:alert-error>
        {{msgError}}
      </template>
    </alert-error>
    <div id="container" class="container">
      <div class="conteudo">
        <table style="width: 100%">
          <thead>
          <tr>
            <th>Nome da campanha</th>
            <th>Custo</th>
            <th>Status</th>
            <th>Ações</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="campaign in campaignsList" :key="campaign.campaign_id">
            <td>{{ campaign.campaign_name }}</td>
            <td>{{ campaign.budget }}</td>
            <td class="campaign-status">
              <span v-if="countCheckBlock()" v-bind:class="getStatus(campaign)">
                {{ getStatus(campaign) }}
              </span>
              <span v-else class="bloqueada">
                bloqueada
              </span>
            </td>
            <td>
              <a v-bind:href="getCampaignManagementLink(campaign)" target="tiktok_business_center">
                <img src="../assets/img/botao-gerenciar-campanha.svg" v-b-tooltip.hover title="Gerenciar campanha">
              </a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RelatorioCampanhas',
  beforeMount() {
    this.getCreateCampaignLink()
    this.getCampaigns();
  },
  mounted() {
    document.title = 'Relatório de Campanhas'
  },
  data: function () {
    return {
      urlCreateCampaign: null,
      campaignsList: [],
      ativas: 0,
      pausadas: 0,
      rejeitadas: 0,
      finalizadas: 0,
      error: false,
      msgError: "",
    }
  },
  computed: {
    linkLoaded: function () {
      return this.urlCreateCampaign !== null
    },
    createCampaignLink: function () {
      return this.urlCreateCampaign
    }
  },
  methods: {
    getCreateCampaignLink() {
      let storeId = this.$cookies.get('Authorization')

      this.$http.get('tiktok/campaigns/creation/url', { headers: {'Authorization': storeId} })
          .then(res => {
            this.urlCreateCampaign = res.data.url
          }).catch(error => {
        this.createCampaignLink = null
        if (error.response) {
          console.log(error.response.status);
          this.msgError = error.response.data.message;
          this.error = true;
        }
      })
    },
    openCampaign() {
      let storeId = this.$cookies.get('Authorization')

      this.$http.get('tiktok/campaigns/creation/url', { headers: {'Authorization': storeId} })
          .then(res => {
            window.open(res.data.url, "campaign");
          }).catch(error => {
        if (error.response) {
          console.log(error.response.status);
          this.msgError = error.response.data.message;
          this.error = true;
        }
      })
    },
    getCampaigns() {
      let storeId = this.$cookies.get('Authorization')

      this.$http.get('tiktok/campaigns', { headers: {'Authorization': storeId} })
          .then(res => {
            console.log(res.data)
            this.campaignsList = res.data
            this.countStatus()
          }).catch(error => {
        if (error.response) {
          console.log(error.response.status);
          this.msgError = error.response.data.message;
          this.error = true;
        }
      })
    },
    getCampaignManagementLink(campaign) {
      return 'https://ads.tiktok.com/i18n/perf/campaign?aadvid=' + campaign.advertiser_id
    },
    countStatus() {
      this.campaignsList.forEach((campaign) => {

        switch (this.getStatus(campaign)) {
          case 'ativa':
            this.ativas++
            break
          case 'pausada':
            this.pausadas++
            break
          case 'rejeitada':
            this.rejeitadas++
            break
          case 'finalizada':
            this.finalizadas++
            break
        }
      })
    },
    getStatus(campaign) {
      switch (campaign.opt_status) {
        case 'DISABLE':
          return 'finalizada' // Finalizada
        case 'ENABLE':
          switch (campaign.status) {
            case 'CAMPAIGN_STATUS_DELETE':
              return 'finalizada' // Apagada
            case 'CAMPAIGN_STATUS_ADVERTISER_AUDIT_DENY':
            case 'ADVERTISER_PUNISH':
            case 'ADVERTISER_ACCOUNT_PUNISH':
              return 'rejeitada' // Falha na revisão do anunciante
            case 'CAMPAIGN_STATUS_ADVERTISER_AUDIT':
              return 'pausada' // Revisão do anunciante em progresso
            case 'ADVERTISER_CONTRACT_PENDING':
              return 'pausada' // Contrato do anunciante não está operacional
            case 'CAMPAIGN_STATUS_BUDGET_EXCEED':
              return 'pausada' // Limite de budget excedido
            case 'CAMPAIGN_STATUS_DISABLE':
              return 'pausada' // Suspensa
            case 'CAMPAIGN_STATUS_ENABLE':
              return 'ativa' // Ativa
          }
      }
    },
    countCheckBlock() {
      if (this.$cookies.get('blockedAccount') === 'block') {
          return false;
      }
      return true; 
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  letter-spacing: 0;
}

div.container {
  max-width: initial;
}

div.conteudo {
  min-height: 400px;
  max-width: initial;
  padding-top: 2em;
  width: 100%;
}

h1 {
  align-self: center;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 0rem;
  margin-top: 1.0rem;
}

div.criar-campanha {
  margin-bottom: 1.6rem;
  padding-right: 1em;
  text-align: right;
}
/*
div.criar-campanha button {
  border-radius: 0.2rem;
  font-size: 0.9rem;
  font-weight: 200;
  padding: 0.2rem 0.8rem;
}
*/
div.summary {
  display: flex;
  justify-content: space-around;
  margin-bottom: 2rem;
}

div.summary div.card-campanha {
  background-color: #FFFFFF;
  margin: 0 0.7rem;
  padding: 1rem;
  width: 100%;
}

div.summary div:first-child {
  margin-left: 0;
}

div.summary div:last-child {
  margin-right: 0;
}

div.summary .card-campanha {
  align-items: center;
  border-radius: 0.2rem;
  display: flex;
}

div.summary .card-campanha {
  border-radius: 0.2rem;
  display: flex;
}

div.summary .icon-campanha {
  align-items: center;
  border-radius: 0.2rem;
  display: flex;
  height: 3.5rem;
  justify-content: center;
  margin-right: 1rem;
  text-align: center;
  width: 3.5rem;
}

div.summary .ativas .icon-campanha {
  background-color: #1ABC9C40;
}

div.summary .pausadas .icon-campanha {
  background-color: #F7B84B40;
}

div.summary .rejeitadas .icon-campanha {
  background-color: #F1556C40;
}

div.summary .finalizadas .icon-campanha {
  background-color: #98A6AD40;
}

div.card-campanha .number {
  display: block;
  font-size: 1.8em;
  font-weight: 600;
  margin-bottom: 0.3rem;
}

div.card-campanha .label {
  color: #98A6AD;
}

thead tr th {
  color: #6C757D;
  font-weight: 600;
  padding: 0 1rem;
}

thead tr th:first-child {
  padding-left: 1em;
}

tbody tr {
  border-bottom: solid 1px #DEE2E6;
}

tbody tr td {
  color: #343A40;
  font-size: 0.9rem;
  padding: 1rem;
}

tbody tr td.campaign-status span {
  background-color: #1ABC9C;
  border-radius: 0.2rem;
  color: #FFFFFF;
  font-size: 0.9rem;
  font-weight: 200;
  padding: 0.2rem 0.8rem;
}

tbody tr td.campaign-status span.ativa {
  background-color: #1ABC9C;
}

tbody tr td.campaign-status span.pausada {
  background-color: #F7B84B;
}

tbody tr td.campaign-status span.rejeitada {
  background-color: #F1556C;
}

tbody tr td.campaign-status span.bloqueada {
  background-color: #F1556C;
}

tbody tr td.campaign-status span.finalizada {
  background-color: #98A6AD;
}
</style>
