<template>
  <div class="tiktok-logo">
    <img src="../assets/img/tiktok-logo.svg">
  </div>
</template>

<script>
export default {
  name: 'TikTokLogo'
}
</script>